import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import zip from 'lodash/zip';

import { addPlaceholderParams, getOptimizedImage, IMAGES_DEFAULT_BREAKPOINTS } from '@utils/image';

import { useImageLoadingContext } from '@common/contexts/imageLoadingContext';
import { videoShape } from '@common/types/cms';
import VideoPlayer from '@common/components/VideoPlayer';

import { AspectRatioWrapper, VideoWrapper } from './LazyImg.styled';

const PLACEHOLDER_IMG =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const Source = React.memo(
  ({
    breakpoint, url, isLoaded, shouldUseLQIP, aspectRatio, width, fpX, fpY, crop,
  }) => {
    const srcSetX1 = getOptimizedImage(url, {
      width,
      aspectRatio,
      crop,
      fpX,
      fpY,
    });
    const srcSetX2 = getOptimizedImage(url, {
      width: width * 2,
      aspectRatio,
      crop,
      fpX,
      fpY,
    });

    const srcSet = `
      ${srcSetX2} 2x,
      ${srcSetX1} 1x
    `;

    const placeholderSrcSet = shouldUseLQIP ? addPlaceholderParams(srcSet, width) : PLACEHOLDER_IMG;
    const [aspectWidth, aspectHeight] = aspectRatio.split(':');
    const height = +(width / (aspectWidth / aspectHeight)).toFixed(2);

    return (
      <source
        media={`(min-width: ${breakpoint}px)`}
        srcSet={isLoaded ? srcSet : placeholderSrcSet}
        width={width}
        height={height}
      />
    );
  }
);

const getVideoSource = sources => {
  if (!sources) {
    return null;
  }

  const { stream, fallback } = sources;

  return {
    src: stream || fallback,
    type: stream ? 'application/x-mpegurl' : 'video/mp4',
  };
};

export function LazyImg({
  alt,
  aspectRatios,
  breakpoints,
  className,
  placeholder,
  src,
  focalPointY,
  focalPointX,
  forceShow,
  width,
  height,
  crop,
  dataTestid,
  draggable,
  widths,
  disableAbsolute,
  video,
  isLookbookTeaser,
  videoDesktop,
}) {
  const { shouldUseLQIP } = useImageLoadingContext();
  const imageRef = useRef(null);
  const [wasInViewport, setWasInViewport] = useState(false);
  const [imageExists, setImageExists] = useState(true);

  useEffect(() => {
    if (forceShow) {
      return () => {};
    }

    const loaderCurrent = imageRef.current;

    function onIntersection(entries, observer) {
      const [firstIntersection] = entries;

      if (firstIntersection.isIntersecting) {
        setWasInViewport(true);
        observer.unobserve(loaderCurrent);
      }
    }

    const options = { threshold: 0 };
    const observer = new IntersectionObserver(
      entries => onIntersection(entries, observer),
      options
    );

    if (loaderCurrent) {
      observer.observe(loaderCurrent);
    }

    return () => observer.unobserve(loaderCurrent);
  }, [src, forceShow]);

  const handleImageError = useCallback(() => {
    if (imageExists) {
      setImageExists(false);
    }
  }, [imageExists]);

  const smallestBreakpointFallback = Math.min(...breakpoints);
  const smallestBreakpointFallbackIndex = breakpoints.indexOf(smallestBreakpointFallback);
  const aspectRatioFallback =
    typeof aspectRatios === 'string' ?
      aspectRatios :
      aspectRatios?.[smallestBreakpointFallbackIndex];
  const optimizedSrc = getOptimizedImage(src, {
    width: smallestBreakpointFallback,
    aspectRatio: aspectRatioFallback,
    crop,
    fpX: focalPointX,
    fpY: focalPointY,
  });
  const placeholderImage = addPlaceholderParams(optimizedSrc, smallestBreakpointFallback);
  const isLoadedAndSrcProvided = wasInViewport && Boolean(optimizedSrc);

  const [aspectWidth, aspectHeight] = aspectRatioFallback.split(':');
  const ar = aspectWidth / aspectHeight;

  let imgSrc;

  if (shouldUseLQIP) {
    imgSrc = forceShow || (imageExists && isLoadedAndSrcProvided) ? optimizedSrc : placeholderImage;
  } else {
    imgSrc = forceShow || wasInViewport ? optimizedSrc : PLACEHOLDER_IMG;
  }

  const breakpointsWithAspectRatios =
    typeof aspectRatios === 'string' ?
      breakpoints.map(breakpoint => [breakpoint, aspectRatios]) :
      zip(breakpoints, aspectRatios);

  const shouldUseVideo =
    video?.stream || video?.fallback || videoDesktop?.stream || videoDesktop?.fallback;

  return (
    <AspectRatioWrapper
      className={className}
      disableAbsolute={disableAbsolute}
      breakpointsWithAspectRatios={breakpointsWithAspectRatios.slice().reverse()}
      withVideo={shouldUseVideo}
    >
      {video && (
        <VideoWrapper>
          <VideoPlayer
            isLookbookTeaser={isLookbookTeaser}
            options={{
              autoplay: true,
              loop: true,
              muted: true,
              controls: false,
              disablePictureInPicture: true,
              poster: src,
              playsinline: true,
              source: getVideoSource(video),
            }}
            useHLS
          />
        </VideoWrapper>
      )}
      {videoDesktop && (
        <VideoWrapper isDesktopVideo>
          <VideoPlayer
            isLookbookTeaser={isLookbookTeaser}
            useHLS
            options={{
              autoplay: true,
              loop: true,
              muted: true,
              controls: false,
              disablePictureInPicture: true,
              poster: src,
              playsinline: true,
              source: getVideoSource(videoDesktop),
            }}
          />
        </VideoWrapper>
      )}
      <picture data-testid={dataTestid}>
        {breakpointsWithAspectRatios.map(([breakpoint, aspectRatio], index) => (
          <Source
            key={breakpoint}
            breakpoint={breakpoint}
            width={widths[index] || breakpoints[Math.max(0, index - 1)]}
            url={imageExists || !placeholder ? src : placeholder}
            aspectRatio={aspectRatio}
            isLoaded={forceShow || wasInViewport}
            shouldUseLQIP={shouldUseLQIP}
            fpX={focalPointX}
            fpY={focalPointY}
            crop={crop}
          />
        ))}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt={alt}
          ref={imageRef}
          src={imgSrc}
          onError={handleImageError}
          width={width || smallestBreakpointFallback}
          height={height || smallestBreakpointFallback / ar}
          draggable={draggable}
        />
      </picture>
    </AspectRatioWrapper>
  );
}

LazyImg.propTypes = {
  dataTestid: PropTypes.string,
  alt: PropTypes.string.isRequired,
  aspectRatios: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
    .isRequired,
  breakpoints: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  crop: PropTypes.string,
  focalPointX: PropTypes.number,
  focalPointY: PropTypes.number,
  forceShow: PropTypes.bool,
  height: PropTypes.number,
  placeholder: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  draggable: PropTypes.bool,
  widths: PropTypes.arrayOf(PropTypes.number),
  disableAbsolute: PropTypes.bool,
  video: videoShape,
  videoDesktop: videoShape,
  isLookbookTeaser: PropTypes.bool,
};

LazyImg.defaultProps = {
  dataTestid: null,
  className: undefined,
  crop: undefined,
  breakpoints: IMAGES_DEFAULT_BREAKPOINTS,
  focalPointX: undefined,
  focalPointY: undefined,
  forceShow: false,
  height: undefined,
  placeholder: undefined,
  width: undefined,
  draggable: undefined,
  widths: [],
  disableAbsolute: false,
  video: null,
  videoDesktop: null,
  isLookbookTeaser: false,
};

Source.propTypes = {
  aspectRatio: PropTypes.string.isRequired,
  breakpoint: PropTypes.number.isRequired,
  crop: PropTypes.string,
  fpX: PropTypes.number,
  fpY: PropTypes.number,
  isLoaded: PropTypes.bool.isRequired,
  shouldUseLQIP: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

Source.defaultProps = {
  crop: undefined,
  fpX: undefined,
  fpY: undefined,
};

export default LazyImg;
